@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

html, body, label {
  font-family: nunito !important;
  height: 100%;
  scroll-behavior: smooth;
  z-index: 10 !important;
  background-color: #fff;
}

.fake-link {
  text-decoration: none !important;
  color: #1ABC9C;
  cursor: pointer;
}

.fake-link:hover {
  color: #000;
}

.dark .copyright-links a {
  color: hsla(0,0%,100%,.4);
  border-bottom-color: hsla(0,0%,100%,.65);
}

#footer.dark, .dark #footer {
  font-size: 14px;
  color: hsla(0,0%,100%,.4) !important;
  background-color: #202020 !important;
  border-top-color: hsla(0,0%,100%,.2) !important;
}

#footer {
  position: relative;
  background-color: #eee !important;
  border-top: 5px solid rgba(0,0,0,.2);
}

.faqTitle h1 {
  margin: 0 0 30px !important;
}

#offering {
  overflow-x: hidden;
}

.form-check-input {
  margin-top: -1rem !important;
}

.zeroMargin {
  margin: 0 !important;
}

.cartTitle {
  margin-bottom: 0px !important;
}

.cartSummarySection .card-text {
  margin-bottom: 0px !important;
}

h1, h2, h3, h4, h5 {
  font-family: nunito !important;
}

.playlist {
  z-index: 5;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.playerCol {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.textoverlay{
  position: absolute !important;
  top: 50%;
  color: white !important;
}

.active {
  color: #FF7F00 !important;
}

.checkList ul {
  list-style: none;
}

.strikethrough {
  background: linear-gradient(to right top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
}


.early-bird {
  font-size: small;
}

.profilePage ul {
  list-style: none;
}

.profilePage .title {
  font-weight: bold;
}

.contactUsFooter a:hover {
  color: #fff !important;
}

.how-to-anchor a{
  color: #000;
  font-weight: 100 !important;
}

.how-to-anchor a:hover {
  color: #FF7F00;
}

.profilePage p {
  margin-bottom: 0px;
}

.profilePage .noPaddingText .col, .col-6, .col-2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.avaniAvittamLink {
  margin-bottom: 0px !important;
}

.offeringCardHeader {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.bm-menu-wrap a {
  color: black !important;
}

.bm-menu-wrap a:hover {
  color: black !important;
}

.bm-item li {
  padding-top: 1em;
}

.imageCredits ul {
  list-style: none;
}
.imageCredits li {
  padding-top: 2% !important;
}

.topContent {
  font-family: nunito;
  width: 100% !important;
}

.padding0 {
  padding: 0 !important;
}

.getStarted {
  justify-content: center;
  
}

.getStartedBtn {
  color: white !important;
  background-color: #FF7F00 !important;
  font-weight: bold !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.incButtons {
  color: white !important;
  background-color: #FF7F00 !important;
  font-weight: bold !important;
  border: none !important;
  height: 20px !important;
  width: 20px !important;
}

.incInput {
  height: 20px !important;
  width: 20px !important;
}

.incButtonsCol {
  padding: 0px !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

label {
  text-transform: none !important;
}

.audioAlert {
  color: red;
  font-weight: bold;
}


.authBg {
  min-height: 85vh !important;
  background-image: url("assests/img/carousel/5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.owl .imgDesktop {
  height: 360px !important;
  width: 100% !important;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden
}

.card-footer {
  background-color: #fff !important;
  border: none !important;
}


.package2Heading {
  margin-bottom: 0 !important;
}

.owl .imgMobile {
  height: 173px !important;

  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden
}

.heading {
  color: #FF7F00;
}

.faqTitle {
  background-color: white;
  text-align: center;
}

.faqTitle h1{
  color: #FF7F00 !important;
  font-weight: bolder;
}

#page-title {
  padding: 0 !important;
}

.beliefsInner {
  width:50% !important;
}

.navList {
  color: black !important;
}

.navBarOverride {
  border-bottom: 1px solid;
}

.loginPill{
  color: black !important;
  font-weight: bolder !important;
}

.formBody Input {
  border-radius: .8em !important;
  height: 50px !important;
}

.formBody select {
  border-radius: .8em !important;
  height: 50px !important;
}

.formBody Form {
  margin-bottom: 0px !important;
}

.packagesContainer .card-body {
  padding-bottom: 0px !important;
}
.detailsFormSection {
  min-height: 85vh !important;
}
.detailsFormCardBody .card-body {
  padding-bottom: 0px !important;
}

.formBody sup {
  color: red;
}

.cartSummarySection sup {
  color: red;
}

.audioPlayer .rhap_header {
  width: 100%;
  text-align: center !important;
}

#increment-component button {
  background-color: #FF7F00 !important;
  font-size: 16px;
  color: #fff;
  border-color: transparent;
  border-radius: 3px;
}

#increment-component div {
  padding: 0px !important;
}

#increment-component input {
  font-size: 16px;
  text-align: center;
  width: 30px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  min-width: 150px !important;
}

.consent-checkbox{
  margin-top: 2px !important;
}

@media only screen and (max-width: 900px){
  .getStartedBtn {
    font-size: 5vw !important;
    font-weight: bold !important;
  }
  .hrLine {
    display: none;
  }
  .faqTitle {
    background-color: white;
    text-align: center;
  }
  .beliefsInner {
    width:100% !important;
  }
  .arrow .svg-inline--fa.fa-md {
    display: none;
  }
  .mobile-hide{
    display: none !important;
  }
}

@media only screen and (min-width: 901px){
  .hrLine {
    display: inline-block;
    width: 15%;
    border-bottom:  2px solid;
    margin-left: auto; margin-right: auto;
    margin-top: 10px; 
    margin-bottom: 10px;;
  }
  .beliefsImage {
    height: 300px;
  }
  .signUp {
    max-width: 55%;
  }
  .desktop-hide{
    display: none !important;
  }
  .packageHover1 {
    visibility: hidden !important;
  }
  .package1 {
    cursor: pointer;
  }
  .package1:hover + .packageHover1 {
    visibility: visible !important;
  }
  .packageHover2 {
    visibility: hidden !important;
  }
  .package2 {
    cursor: pointer;
  }
  .package2:hover + .packageHover2 {
    visibility: visible !important;
  }
  .chakraSpinnerLeft20 {
    background-image: url('assests/img/chakra.svg');
    top: 20% !important;
    width: 300px;
    height: 300px; 
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    left: -150px !important;
    position: absolute !important;
  }
  .chakraSpinnerRight20 {
    background-image: url('assests/img/chakra.svg');
    top: 20% !important;
    right: -150px !important;
    width: 300px;
    height: 300px; 
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute !important;
  }
  .chakraSpinnerLeft45 {
    background-image: url('assests/img/chakra.svg');
    top: 45% !important;
    width: 300px;
    height: 300px; 
    display: block;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-size: cover;
    left: -150px !important;
    position: absolute !important;
  }
  .chakraSpinnerRight45 {
    background-image: url('assests/img/chakra.svg');
    top: 45% !important;
    right: -150px !important;
    width: 300px;
    height: 300px; 
    display: block;
    opacity: 0.3;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute !important;
  }
  .img{
    width: 540px !important;
  }
  .tab-container {
    width: 500px !important;
  }

  .offeringContainer .card {
    height: 350px !important  ;
  }
  
  .packagesContainer .card {
    height: 420px !important  ;
  }

  .detailsCardBody {
    padding: 40px !important;
  }
}

.navDropToggle .dropdown {
  position: relative;
  display: inline-block;
}

.navDropToggle .dropdown-content {
  position: absolute;
  min-width: 160px;
  overflow: auto;
  background-color: #f8f9fa;
  z-index: 1;
}

.detailsCardBody h4 {
  margin-bottom: 0px;
}

.navDropToggle .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navDropToggle button.dropbtn{
  background:none !important;
  border:none;
  padding:0;
}

.navDropToggle .dropdown a:hover {background-color: #ddd;}
.show {display: block;}

.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 20px;
  height: 20px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

.arrow .svg-inline--fa.fa-md {
  vertical-align: 0 !important;
  color: #FF7F00 !important;
}

.formContainer {
  border-radius: 2.5em !important;
}


.sidenav {
  height: 100%;
  width: 10vw;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #DFE0E5;
  overflow-x: hidden;
  transition: 0.5s;
}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
